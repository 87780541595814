/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import FormLoader from "../../../components/formLoader";
import { withTheme } from "react-jsonschema-form";
import { Theme as MuiTheme } from "rjsf-material-ui";
import $ from "jquery";
import LinkItem from "../../../components/linkItem";
import { withSnackbar } from "notistack";
import { authenticate, getAllUserRoles } from "../../../servies/services";
import CryptoJS from "crypto-js";

const Form = withTheme(MuiTheme);
const schema = require("./schema.json");
const uiSchema = {
  Password: {
    "ui:widget": "password",
  },
};

class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: schema,
      uiSchema: uiSchema,
      formData: {},
      loading: false,
      OrgLoading: false,
      role_list: [],
      remainingAttempts: 3,
    };
  }

  onChange = (e) => {
    const formData = this.state.formData;
    formData[e.target.name] = e.target.value;
    this.setState({
      formData,
    });
    console.log("formData", this.state.formData);
  };

  onSubmit = async () => {
    console.log("this.state.formData", this.state.formData);
    const { UserName, Password } = this.state.formData;
    if (!UserName || !Password) {
      const message = "Please fill all fields";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 5000,
      });
      return;
    }

    const normalizedUserName = UserName.toLowerCase();

    let data = {
      UserName,
      Password,
      OrgName: "",
    };

    if (normalizedUserName === "adminag") {
      data.OrgName = "trustee";
      data.UserName = "AdminAG";
    } else if (normalizedUserName === "trustee"){
      data.OrgName = "trustee";
      data.UserName = "Trustee";
    }
    else if (normalizedUserName === "angelogordon") {
      data.OrgName = "investor";
      data.UserName = "AngeloGordon";
    } else if (normalizedUserName === "investor2") {
      data.OrgName = "investor";
      data.UserName = "Investor2";
    } else if (normalizedUserName === "investor3") {
      data.OrgName = "investor";
      data.UserName = "Investor3";
    } else if (normalizedUserName === "servicer") {
      data.OrgName = "servicer";
      data.UserName = "Servicer";
    } else if (normalizedUserName === "admin") {
      data.OrgName = "trustee";
      data.UserName = "admin";
    } else {
      const message = "Invalid username";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 5000,
      });
      return;
    }

    this.authenticate(data);
  };

  authenticate = async (data) => {
    this.setState({ loading: true });
    const APIResponse = await authenticate(data);
    const normalizedUserName = this.state.formData.UserName.toLowerCase();

    this.setState({ loading: false });

    if (APIResponse) {
      if (APIResponse.status == 204) {
        this.setState({ remainingAttempts: this.state.remainingAttempts - 1 });

        if (this.state.remainingAttempts <= 0) {
          this.setState({ loading: true });
          setTimeout(
            () => this.setState({ loading: false, remainingAttempts: 3 }),
            180000
          );

          const message =
            "Your login attempts expired please wait for 3 minutes";
          this.props.enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          const message = "Credentials Incorrect";
          this.props.enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      } else if (APIResponse.status !== 200) {
        const message = "Something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 5000,
        });
      } else {
        if (APIResponse.data.isSuccess == false) {
          const message = "Credentials Incorrect";
          this.props.enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        } else {
          const { OrgName, UserID, EmailID } = APIResponse.data.data;
          sessionStorage.setItem("ChannelName", "nplchannel");
          sessionStorage.setItem("OrgName", OrgName);
          sessionStorage.setItem("user_id", UserID);
          sessionStorage.setItem("user_name", data.UserName);
          sessionStorage.setItem("token", APIResponse.data.response.token);
          sessionStorage.setItem("emailid", EmailID);

          const pass = this.state.formData.Password;
          const key = CryptoJS.enc.Latin1.parse("ALtReKQqUH1VTh43vNomog==");
          const iv = CryptoJS.enc.Latin1.parse("9988543267190345");
          const encrypted = CryptoJS.AES.encrypt(pass, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding,
          });
          sessionStorage.setItem("Pass", encrypted);

          const peers = [`peer0-${OrgName}`];
          sessionStorage.setItem("peer", peers[0]);
          sessionStorage.setItem("peers", JSON.stringify(peers));

          const message = "Logged in successfully";
          this.props.enqueueSnackbar(message, {
            variant: "info",
            autoHideDuration: 2000,
          });

          if (normalizedUserName === "adminag" ) {
            sessionStorage.setItem("userrole", "AdminAG");
            window.location.assign("/dashboard");
          } else if (normalizedUserName === "trustee"){
            sessionStorage.setItem("userrole", "Trustee");
            window.location.assign("/dashboard");
          }else if (normalizedUserName === "angelogordon") {
            sessionStorage.setItem("userrole", "Investor");
            window.location.assign("/investor/dashboard");
          } else if (normalizedUserName === "investor2") {
            sessionStorage.setItem("userrole", "Investor2");
            window.location.assign("/investor/dashboard");
          } else if (normalizedUserName === "investor3") {
            sessionStorage.setItem("userrole", "Investor3");
            window.location.assign("/investor/dashboard");
          } else if (normalizedUserName === "admin") {
            sessionStorage.setItem("userrole", "admin");
            window.location.assign(
              "/admin/users/" + OrgName + "/Pending"
            );
          } else {
            window.location.assign("/dashboard/servicer");
          }
        }
      }
    }
  };

  async componentDidMount() {
    sessionStorage.clear();
    this.getAllUserRoles();

    if (sessionStorage.getItem("OrgName") === null) {
      this.getAllUserRoles();
    } else {
      const OrgName = JSON.parse(sessionStorage.getItem("OrgName"));
      this.setState({ OrgLoading: true, role_list: OrgName });
    }
  }

  getAllUserRoles = async () => {
    const APIResponse = await getAllUserRoles();
    if (APIResponse) {
      console.log("getAllUserRoles APIResponse.data", APIResponse.data);
      this.setState({ OrgLoading: true, role_list: APIResponse.data });
      sessionStorage.setItem("OrgName", JSON.stringify(APIResponse.data));
    } else {
      this.setState({ OrgLoading: true });
    }
  };

  render() {
    const x = this.state.formData;
    console.log("XXX", x);
    return (
      <React.Fragment>
        {this.state.loading && <FormLoader />}

        <React.Fragment>
          <div className="modalshiftcontent">
            <div className="input-container">
              <label className="label">Username</label>
              <input
                name="UserName"
                onChange={this.onChange}
                className="input"
                type="text"
                value={this.state.formData.UserName}
              />
            </div>
            <div className="input-container">
              <label className="label">Password</label>
              <input
                name="Password"
                type="password"
                onChange={this.onChange}
                className="input"
                value={this.state.formData.Password}
              />
            </div>
            <p>
              <Button
                className="col-md-12"
                onClick={this.onSubmit}
                variant="contained"
                size="large"
                color="primary"
                id="signinbutton"
                type="submit"
                disabled={this.state.loading}
              >
                Sign in
              </Button>
            </p>
          </div>
        </React.Fragment>

        <div className="loginCheckbox">
          <p className="lineDivider">
            <span>New to IntainADMIN?</span>
          </p>
          <LinkItem to={"/register"} variant="contained" className="loginBtn" title={"Create your Account"} />
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(login);
