import React, { useState, useEffect } from "react";
import { Table } from "antd";
import "./DataCheckDash.css";
import AccordIcon from "../../../../../../images/AccordIcon.png";
import Button from "@material-ui/core/Button";

// Utility function to format camelCase to Title Case
const formatTableKey = (key) => {
  return key
    .replace(/([A-Z])/g, " $1") // Insert a space before each uppercase letter
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};

const parseDataString = (dataString) => {
  try {
    const parsed = JSON.parse(dataString);
    // console.log("Parsed JSON:", parsed);

    if (parsed.length === 0) return { headers: {}, data: [] };

    // Extract headers from the first object
    const headers = Object.keys(parsed[0]).reduce((acc, key) => {
      const headerValue = parsed[0][key]["0"]; // Access the nested value
      acc[key] = headerValue || key; // Use key as fallback if value is missing
      return acc;
    }, {});

    // console.log("Extracted Headers:", headers);

    // Initialize an array to store the flattened data
    const data = [];

    // Iterate over the parsed data and flatten it
    Object.keys(parsed[0]).forEach((headerKey) => {
      Object.keys(parsed[0][headerKey]).forEach((indexKey) => {
        if (indexKey !== "0") {
          const row = data[indexKey - 1] || { key: indexKey - 1 }; // Use index as unique key
          row[headerKey] = parsed[0][headerKey][indexKey];
          data[indexKey - 1] = row;
        }
      });
    });

    // console.log("Processed Data:", data);
    return { headers, data };
  } catch (error) {
    console.error("Failed to parse data string:", error);
    return { headers: {}, data: [] };
  }
};

const generateColumns = (headers) => {
  if (!headers || Object.keys(headers).length === 0) return [];

  return Object.keys(headers).map((key, index) => ({
    title: headers[key], // Set the column title from the headers
    dataIndex: key, // Match with the dataIndex in parseDataString
    key: key, // Unique key for each column
    fixed: index === 0 ? "left" : false, // Freeze the first column
  }));
};

function DataCheckDash() {
  const [tablesData, setTablesData] = useState({
    agingUnderContract: { data: [], columns: [] },
    futureRenoDate: { data: [], columns: [] },
    futureSaleDate: { data: [], columns: [] },
    futureUnderContractDate: { data: [], columns: [] },
    initialValuationDateCompliance: { data: [], columns: [] },
    renoCostsMissing: { data: [], columns: [] },
    soldButNotRemoved: { data: [], columns: [] },
    statusMismatchCheck: { data: [], columns: [] },
    underContractAtALoss: { data: [], columns: [] },
    valuationMissing: { data: [], columns: [] },
  });

  const [isMainAccordionOpen, setIsMainAccordionOpen] = useState(true);
  const [isRestrictedAccordionOpen, setIsRestrictedAccordionOpen] =
    useState(true);

  useEffect(() => {
    const finalData = JSON.parse(sessionStorage.getItem("finalDataDataChecks"));
    // console.log("finalData", finalData);

    if (finalData) {
      const updatedTablesData = {};

      Object.keys(finalData[0]).forEach((key) => {
        const { headers, data } = parseDataString(finalData[0][key]);
        // console.log("Parsed Headers:", headers);
        // console.log("Parsed Data:", data);

        const columns = generateColumns(headers); // Generate columns using extracted headers
        updatedTablesData[key] = { data, columns };
      });
      // console.log("updatedTablesData", updatedTablesData);

      setTablesData(updatedTablesData);
    }
  }, []);

  // Toggle function for Main Accordion
  const handleMainAccordion = () => {
    setIsMainAccordionOpen((prevState) => !prevState);
  };

  // Toggle function for Restricted Accordion
  const handleRestrictedAccordion = () => {
    setIsRestrictedAccordionOpen((prevState) => !prevState);
  };

  return (
    <div className="dataCheckDash">
      {/* Main Accordion */}
      <div className="AccordianMainFunding">
        <div className="mainAccordian">Main</div>
        <div className="AccordianFunding">
          <Button
            variant="contained"
            color="primary"
            className="publishFunding"
            // onClick={this.PublishReport}
          >
            Publish
            {/* {this.state.formLoader1 === true ? (
              <CircularProgress size="22px" color="primary" />
            ) : (
              ""
            )} */}
          </Button>
          <img
            src={AccordIcon}
            alt="Accordian"
            className={`publishFundingImg ${
              isMainAccordionOpen ? "AccordImgGen" : "AccordImgGenReverse"
            }`}
            onClick={handleMainAccordion}
          />
        </div>
      </div>

      {/* Conditionally render the first 5 tables */}
      {isMainAccordionOpen && (
        <>
          {Object.keys(tablesData)
            .slice(0, 5)
            .map((tableKey) => {
              const { data, columns } = tablesData[tableKey];
              return (
                <div
                  key={tableKey}
                  className="workbench-table-container dataCheck-container"
                >
                  <label className="dataCheck-tableNames">
                    {formatTableKey(tableKey)}
                  </label>
                  <div className="dash-table-alignments">
                    <Table
                      columns={columns}
                      className="performance-table"
                      dataSource={data}
                      pagination={{ pageSize: 10 }}
                      scroll={{ x: "max-content" }}
                    />
                  </div>
                </div>
              );
            })}
        </>
      )}

      {/* Restricted Accordion */}
      <div className="AccordianMainFunding">
        <div className="nameAccordianDataCheckRestricted">Restricted</div>
        <div className="AccordianDataCheckRestricted">
          <img
            src={AccordIcon}
            alt="Restricted Accordian"
            className={
              isRestrictedAccordionOpen ? "AccordImgGen" : "AccordImgGenReverse"
            }
            onClick={handleRestrictedAccordion}
          />
        </div>
      </div>

      {/* Conditionally render the next 5 tables */}
      {isRestrictedAccordionOpen && (
        <>
          {Object.keys(tablesData)
            .slice(5, 10)
            .map((tableKey) => {
              const { data, columns } = tablesData[tableKey];
              return (
                <div
                  key={tableKey}
                  className="workbench-table-container dataCheck-container-restricted"
                >
                  <label className="dataCheck-tableNames">
                    {formatTableKey(tableKey)}
                  </label>
                  <div className="dash-table-alignments">
                    <Table
                      columns={columns}
                      className="performance-table"
                      dataSource={data}
                      pagination={{ pageSize: 10 }}
                      scroll={{ x: "max-content" }}
                    />
                  </div>
                </div>
              );
            })}
        </>
      )}
    </div>
  );
}

export default DataCheckDash;
