import React, { Component } from "react";
import CacheBuster from "./CacheBuster";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ScrollToTop from "./components/scrollToTop";
import setUpAxios from "./interceptor";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import LayoutRoute from "./container/LayoutRoute";
import LoginLayoutRoute from "./container/LoginLayoutRoute";
import UpdateProfile from "./container/admin/UpdateProfile/UpdateProfile";
import Users from "./container/admin/users/Users";
import ErrorPage from "./components/custom_404";

import Dashboard from "./container/admin/IARedesign/DealCreation/Dashboard/Dashboard";
import Tranches_DealCreation from "./container/admin/IARedesign/DealCreation/Tranches/Tranches";
import PaymentRules_DealCreation from "./container/admin/IARedesign/DealCreation/PaymentRules/PaymentRules";
import Variables_DealCreation from "./container/admin/IARedesign/DealCreation/Variables/Variables";
import General_DealCreation from "./container/admin/IARedesign/DealCreation/General/General";
import Account_DealCreation from "./container/admin/IARedesign/DealCreation/Account/Account";
import Fees_DealCreation from "./container/admin/IARedesign/DealCreation/Fees/Fees";
import Test_DealCreation from "./container/admin/IARedesign/DealCreation/Tests/Tests";
import Expense_DealCreation from "./container/admin/IARedesign/DealCreation/Expenses/Expenses";

import General_Recurring from "./container/admin/IARedesign/DealRecurring/General_Recurring/General_Recurring";
import Tranches_Recurring from "./container/admin/IARedesign/DealRecurring/Tranches_Recurring/Tranches_Recurring";

import ServicerMainDashboard from "./container/admin/IARedesign/Servicer/ServicerDashboard";
import UploadLoanTape from "./container/admin/IARedesign/LoanProcessing/UploadLoantape/UploadLoanTape";
import ViewDetails_Deal from "./container/admin/IARedesign/ViewDealDetails/ViewDetails/ViewDetails";
import Preview_LoanTape from "./container/admin/IARedesign/LoanProcessing/Preview_LoanTapeDetails/Preview_LoanTapeDetails";
import Preview_Mapped_fields from "./container/admin/IARedesign/LoanProcessing/PreviewMapFields/PreviewMapFields";
import Map_Fields from "./container/admin/IARedesign/LoanProcessing/MapFields/MapFields";
import Summarize_Servicer from "./container/admin/IARedesign/LoanProcessing/Summarize/Summarize";

import Fees_Recurring from "./container/admin/IARedesign/DealRecurring/Fees_Recurring/Fees_Recurring";
import Expenses_Recurring from "./container/admin/IARedesign/DealRecurring/Expenses_Recurring/Expenses_Recurring";
import Variable_Recurring from "./container/admin/IARedesign/DealRecurring/Variable_Recurring/Variable_Recurring";
import Tests_Recurring from "./container/admin/IARedesign/DealRecurring/Tests_Recurring/Tests_Recurring";
import Payment_Recurring from "./container/admin/IARedesign/DealRecurring/Payment_Recurring/Payment_Recurring";

import Login from "./container/admin/Login/login";
import Accounts_Recurring from "./container/admin/IARedesign/DealRecurring/Accounts_Recurring/Accounts_Recurring";
import CollateralReport from "./container/admin/IARedesign/DealRecurring/CollateralReport/CollateralReport";
import InvestorReport from "./container/admin/IARedesign/DealRecurring/InvestorReport/InvestorReport";
import BDBReport from "./container/admin/IARedesign/DealRecurring/BDBReport/BDBReport";
import InvestorDashboard from "./container/admin/IARedesign/InvestorLogins/InvestorDashboard/InvestorDashboard";
import LoanTapeRecurring from "./container/admin/IARedesign/DealRecurring/LoanTapeRecurring/LoanTapeRecurring";
import ConsolidatedRecurring from "./container/admin/IARedesign/DealRecurring/ConsolidatedRecurring/ConsolidatedRecurring";
import Register from "./container/admin/Register/Register";
import Files_Recurring from "./container/admin/IARedesign/DealRecurring/Files_Recurring/Files_Recurring";
import Collateral_InvRecurring from "./container/admin/IARedesign/InvestorLogins/Collateral_InvRecurring/Collateral_InvRecurring";
import Report_InvRecurring from "./container/admin/IARedesign/InvestorLogins/Report_InvRecurring/Report_InvRecurring";
import Files_InvRecurring from "./container/admin/IARedesign/InvestorLogins/Files_Inv/Files_Inv";
import LoanTapes_InvRecurring from "./container/admin/IARedesign/InvestorLogins/LoanTapes_InvRecurring/LoanTapes_InvRecurring";
import FundingReportsDealAnalytics from "./container/admin/IARedesign/InvestorLogins/Summary/FundingReportsDealAnalytics";
import StdFields from "./container/admin/StdFields/StdFields";

import On_Demand from "./container/admin/IARedesign/InvestorLogins/On_Demand/On_Demand";
import IssuerDashboard from "./container/admin/IARedesign/IssuerLogins/IssuerDashboard/IssuerDashboard";
import On_Demand_Issuer from "./container/admin/IARedesign/IssuerLogins/On_Demand_Issuer/On_Demand_Issuer";
import DealAnalytics from "./container/admin/IARedesign/IssuerLogins/DealAnalytics/DealAnalytics";
import PortfolioAnalytics from "./container/admin/IARedesign/IssuerLogins/PortfolioAnalytics/PortfolioAnalytics";
import Report_IssuerRecurring from "./container/admin/IARedesign/IssuerLogins/Report_IssuerRecurring/Report_IssuerRecurring";
import Collateral_IssuerRecurring from "./container/admin/IARedesign/IssuerLogins/Collateral_IssuerRecurring/Collateral_IssuerRecurring";
import LoanTapes_IssuerRecurring from "./container/admin/IARedesign/IssuerLogins/LoanTapes_IssuerRecurring/LoanTapes_IssuerRecurring";
import Files_IssuerRecurring from "./container/admin/IARedesign/IssuerLogins/Files_IssuerRecurring/Files_IssuerRecurring";
import BorrowingBase from "./container/admin/IARedesign/DealCreation/BorrowingBase/BorrowingBase";
import BorrowingBaseRecurring from "./container/admin/IARedesign/DealRecurring/BorrowingBaseRecurring/BorrowingBaseRecurring";
import Annex from "./container/admin/IARedesign/DealCreation/Esma_Reporting/Annex";
import Annex12 from "./container/admin/IARedesign/DealCreation/Esma_Reporting/Annex12";
import Annex14 from "./container/admin/IARedesign/DealCreation/Esma_Reporting/Annex14";
import Annex_Inv from "./container/admin/IARedesign/InvestorLogins/Esma_Inv_Reporting/Annex_Inv";
import Annex12_Inv from "./container/admin/IARedesign/InvestorLogins/Esma_Inv_Reporting/Annex12_Inv";
import Annex14_Inv from "./container/admin/IARedesign/InvestorLogins/Esma_Inv_Reporting/Annex14_Inv";
import Manual_Input from "./container/admin/IARedesign/DealCreation/ManualInput/Manual_Input";
import ManualInput_recurring from "./container/admin/IARedesign/DealRecurring/ManualInput_Recuring/ManualInput_recurring";
import UploadClosing_Loantape from "./container/admin/IARedesign/ClosingLoanProcessing/UploadClosing_Loantape/UploadClosing_Loantape";
import PreviewClosing_LoanTapeDetails from "./container/admin/IARedesign/ClosingLoanProcessing/PreviewClosing_LoanTapeDetails/PreviewClosing_LoanTapeDetails";
import Closing_MapFields from "./container/admin/IARedesign/ClosingLoanProcessing/Closing_MapFields/Closing_MapFields";
import PreviewClosing_MapFields from "./container/admin/IARedesign/ClosingLoanProcessing/PreviewClosing_MapFields/PreviewClosing_MapFields";
import MonthlyReportsDealAnalytics from "./container/admin/IARedesign/InvestorLogins/Summary/MonthlyReportsDealAnalytics";
import AssetReviewDealAnalytics from "./container/admin/IARedesign/InvestorLogins/Summary/AssetReviewDealAnalytics";
import DataChecksDealAnalytics from "./container/admin/IARedesign/InvestorLogins/Summary/DataChecksDealAnalytics";
import StandardPortfolio from "./container/admin/IARedesign/InvestorLogins/Standard/StandardPortfolio";
import OnDemandPortfolio from "./container/admin/IARedesign/InvestorLogins/Standard/OnDemandPortfolio";
// import Profile from "./container/admin/Profile/Profile";

const history = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FFC000",
      light: "#FFC000",
      dark: "#FFC000",
    },
    secondary: {
      main: "#49ae46",
      light: "#d5f2f0",
      dark: "#2e9a2b",
    },
  },
  props: {
    MuiButton: {
      size: "medium",
    },
    MuiFilledInput: {},
    MuiFormControl: {},
    MuiFormHelperText: {},
    MuiIconButton: {
      size: "medium",
    },
    MuiInputBase: {},
    MuiInputLabel: {},
    MuiListItem: {
      dense: true,
    },
    MuiOutlinedInput: {},
    MuiFab: {
      size: "medium",
    },
    MuiTable: {
      size: "medium",
    },
    MuiTextField: {
      variant: "filled",
      size: "medium",
    },
    MuiToolbar: {
      variant: "dense",
    },
  },

  overrides: {},
});

class App extends Component {
  render() {
    setUpAxios();
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <div className="App">
              <ThemeProvider theme={theme}>
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  maxSnack={3}
                >
                  <Router history={history}>
                    <ScrollToTop />
                    <Switch>
                      <LoginLayoutRoute
                        exact={true}
                        path="/"
                        component={Login}
                      />
                      <LoginLayoutRoute
                        exact={true}
                        path="/register"
                        component={Register}
                      />
                      <LoginLayoutRoute
                        exact={true}
                        path="/update-profile"
                        component={UpdateProfile}
                      />
                      {/* <LoginLayoutRoute
                        exact={true}
                        path="/admin/profile"
                        component={Profile}
                      /> */}
                      <LayoutRoute
                        exact={true}
                        pageTitle={"Users"}
                        path="/admin/users/:orgname/:status"
                        component={Users}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/trustee/setup-page"
                        component={StdFields}
                      />

                      <LayoutRoute
                        exact={true}
                        path="/admin/account"
                        component={Account_DealCreation}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/tranches"
                        component={Tranches_DealCreation}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/paymentrules"
                        component={PaymentRules_DealCreation}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/variables"
                        component={Variables_DealCreation}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/general"
                        component={General_DealCreation}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/fees"
                        component={Fees_DealCreation}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/manualinput"
                        component={Manual_Input}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/annex"
                        component={Annex}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/annex12"
                        component={Annex12}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/annex14"
                        component={Annex14}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/tests"
                        component={Test_DealCreation}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/borrowingBase"
                        component={BorrowingBase}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/expenses"
                        component={Expense_DealCreation}
                      />

                      <LayoutRoute
                        exact={true}
                        path="/admin/general_recurring"
                        component={General_Recurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/tranches_recurring"
                        component={Tranches_Recurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/fees_recurring"
                        component={Fees_Recurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/expenses_recurring"
                        component={Expenses_Recurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/payment_recurring"
                        component={Payment_Recurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/variable_recurring"
                        component={Variable_Recurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/tests_recurring"
                        component={Tests_Recurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/borrowingbase_recurring"
                        component={BorrowingBaseRecurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/accounts_recurring"
                        component={Accounts_Recurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/collateral_recurring"
                        component={CollateralReport}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/loantape_recurring"
                        component={LoanTapeRecurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/consolidated_recurring"
                        component={ConsolidatedRecurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/files_recurring"
                        component={Files_Recurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/viewInvestorReport"
                        component={InvestorReport}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/viewBDBReport"
                        component={BDBReport}
                      />

                      <LayoutRoute
                        exact={true}
                        path="/admin/uploadloantape"
                        component={UploadLoanTape}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/previewloantapedetails"
                        component={Preview_LoanTape}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/mapfields"
                        component={Map_Fields}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/previewmapfields"
                        component={Preview_Mapped_fields}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/summarize"
                        component={Summarize_Servicer}
                      />

                      {/* From Start of Closinig Date */}
                      <LayoutRoute
                        exact={true}
                        path="/admin/closing_uploadloantape"
                        component={UploadClosing_Loantape}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/closing_previewloantapedetails"
                        component={PreviewClosing_LoanTapeDetails}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/closing_mapfields"
                        component={Closing_MapFields}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/closing_previewmapfields"
                        component={PreviewClosing_MapFields}
                      />
                      {/* To End of Closinig Date */}

                      <LayoutRoute
                        exact={true}
                        path="/dashboard"
                        component={Dashboard}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/dashboard/servicer"
                        component={ServicerMainDashboard}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/admin/viewdetails"
                        component={ViewDetails_Deal}
                      />

                      <LayoutRoute
                        exact={true}
                        path="/investor/dashboard"
                        component={InvestorDashboard}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/issuer/dashboard"
                        component={IssuerDashboard}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/investor/report_InvRecurring"
                        component={Report_InvRecurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/investor/collateral_InvRecurring"
                        component={Collateral_InvRecurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/investor/file_InvRecurring"
                        component={Files_InvRecurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/investor/loantape_InvRecurring"
                        component={LoanTapes_InvRecurring}
                      />

                      <LayoutRoute
                        exact={true}
                        path="/investor/ondemand_investor"
                        component={On_Demand}
                      />
                      {/* BDB Things Start */}
                      <LayoutRoute
                        exact={true}
                        path="/investor/portfolio/standard"
                        component={StandardPortfolio}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/investor/portfolio/onDemand"
                        component={OnDemandPortfolio}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/investor/dealAnalytics/fundingReports"
                        component={FundingReportsDealAnalytics}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/investor/dealAnalytics/monthlyReports"
                        component={MonthlyReportsDealAnalytics}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/investor/dealAnalytics/assetReview"
                        component={AssetReviewDealAnalytics}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/investor/dealAnalytics/dataChecks"
                        component={DataChecksDealAnalytics}
                      />

                      {/* BDB Things End */}
                      <LayoutRoute
                        exact={true}
                        path="/issuer/report_IssuerRecurring"
                        component={Report_IssuerRecurring}
                      />

                      <LayoutRoute
                        exact={true}
                        path="/issuer/collateral_IssuerRecurring"
                        component={Collateral_IssuerRecurring}
                      />

                      <LayoutRoute
                        exact={true}
                        path="/issuer/loantape_IssuerRecurring"
                        component={LoanTapes_IssuerRecurring}
                      />

                      <LayoutRoute
                        exact={true}
                        path="/admin/manualinput_recurring"
                        component={ManualInput_recurring}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/issuer/file_IssuerRecurring"
                        component={Files_IssuerRecurring}
                      />

                      <LayoutRoute
                        exact={true}
                        path="/issuer/standard_issuer"
                        component={PortfolioAnalytics}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/issuer/ondemand_issuer"
                        component={On_Demand_Issuer}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/issuer/summary_issuer"
                        component={DealAnalytics}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/investor/Annex_Inv"
                        component={Annex_Inv}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/investor/Annex12_Inv"
                        component={Annex12_Inv}
                      />
                      <LayoutRoute
                        exact={true}
                        path="/investor/Annex14_Inv"
                        component={Annex14_Inv}
                      />

                      <LayoutRoute
                        exact={true}
                        path="*"
                        component={ErrorPage}
                      />
                    </Switch>
                  </Router>
                </SnackbarProvider>
              </ThemeProvider>
            </div>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
