import React from "react";
import { TextField, Box } from "@mui/material";

const ChatAssistant = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        "& legend": { display: "none" },
        "& fieldset": { top: 0 },
      }}
    >
      <TextField
        variant="outlined"
        placeholder="Ida (Virtual Analyst) ~ Ask Anything"
        fullWidth
        InputProps={{
          sx: {
            borderRadius: "20px",
            height: "38px", // Set the height of the input field
            padding: "0 10px", // Adjust padding to allow more space for text
            fontFamily: "Mulish, sans-serif", // Apply Mulish font to input text
          },
        }}
        sx={{
          width: "340px !important", // Increase width to fit the placeholder text
          "& .MuiInputBase-input::placeholder": {
            fontFamily: "Mulish, sans-serif", // Apply Mulish font to placeholder
            fontSize: "14px", // Adjust font size if needed
          },
        }}
      />
    </Box>
  );
};

export default ChatAssistant;
