import React, { useState, useEffect } from "react";
import { Table } from "antd";
import "./FundingDash.css";
import AccordIcon from "../../../../../../images/AccordIcon.png";
import NumberComp2 from "../../../../../../components/NumberComp2";
import Button from "@material-ui/core/Button";

// Process Table1 data
const processTable1Data = (data) => {
  if (!Array.isArray(data) || data.length === 0) return [];

  return data.map((item, index) => ({
    key: index,
    name: item?.name || "N/A", // Handle missing or undefined 'name'
    value: item?.value !== null && item?.value !== undefined ? item.value : "-", // Handle null or undefined 'value'
  }));
};

// Process Table2 data
const processTable2Data = (data) => {
  if (!Array.isArray(data) || data.length === 0) return [];

  return data.map((row, index) => ({
    key: index,
    column: row.find((item) => item.name === "Column")?.value || "-", // Handle missing 'Column'
    classA: row.find((item) => item.name === "Class A")?.value || "-", // Handle missing 'Class A'
    classB: row.find((item) => item.name === "Class B")?.value || "-", // Handle missing 'Class B'
    total: row.find((item) => item.name === "Total")?.value || "-", // Handle missing 'Total'
  }));
};

// Generic function to process any table data
const processGenericTableData = (data) => {
  if (!Array.isArray(data) || data.length === 0) return [];

  return data.map((row, rowIndex) => {
    const rowData = { key: rowIndex };

    if (Array.isArray(row)) {
      row.forEach((item) => {
        if (item && item.name) {
          rowData[item.name] =
            item.value !== null && item.value !== undefined ? item.value : "-";
        }
      });
    }

    return rowData;
  });
};

// Define columns for generic tables dynamically
const generateColumns = (data) => {
  if (!Array.isArray(data) || data.length === 0) return [];

  return Object.keys(data[0])
    .filter((key) => key !== "key")
    .map((name) => ({
      title: name,
      dataIndex: name,
      key: name,
      // Uncomment the render function if needed to format numeric values
      // render: (value) => {
      //   const num = parseFloat(value);
      //   return isNaN(num)
      //     ? value
      //     : num.toLocaleString(undefined, {
      //         minimumFractionDigits: 2,
      //         maximumFractionDigits: 2,
      //       });
      // },
    }));
};

// Define the columns for Table1
const columnsTable1 = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    render: (value) => {
      const num = parseFloat(value);
      return isNaN(num)
        ? value
        : num.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
    },
  },
];

// Define the columns for Table2
const columnsTable2 = [
  {
    title: "Column",
    dataIndex: "column",
    key: "column",
    fixed: "left",
    width: 200,
  },
  {
    title: "Class A",
    dataIndex: "classA",
    key: "classA",
    render: (value) => <NumberComp2 value={value} />,
  },
  {
    title: "Class B",
    dataIndex: "classB",
    key: "classB",
    render: (value) => <NumberComp2 value={value} />,
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    render: (value) => <NumberComp2 value={value} />,
  },
];

function FundingDash() {
  const [table1Data, setTable1Data] = useState([]);
  const [table2Data, setTable2Data] = useState([]);
  const [table4Data, setTable4Data] = useState([]);
  const [table3AddsData, setTable3AddsData] = useState([]);
  const [table3RemovalsData, setTable3RemovalsData] = useState([]);
  const [table5Data, setTable5Data] = useState([]);
  const [columnsTable4, setColumnsTable4] = useState([]);
  const [columnsTable3Adds, setColumnsTable3Adds] = useState([]);
  const [columnsTable3Removals, setColumnsTable3Removals] = useState([]);
  const [columnsTable5, setColumnsTable5] = useState([]);
  const [tableTitles, setTableTitles] = useState([]);
  const [isAccordian, setIsAccordian] = useState(false);
  const [isRestrictedAccordian, setIsRestrictedAccordian] = useState(false);

  // Toggle function for Main Accordian
  const handleAccordian = () => {
    setIsAccordian((prevState) => !prevState);
  };

  // Toggle function for Restricted Accordian
  const handleRestrictedAccordian = () => {
    setIsRestrictedAccordian((prevState) => !prevState);
  };

  useEffect(() => {
    const finalData = JSON.parse(sessionStorage.getItem("finalDataFunding"));

    if (finalData) {
      // Extract table keys and set titles
      const keys = Object.keys(finalData.result);
      setTableTitles(keys);

      // Process Table1 data if available
      if (finalData.result.Table1) {
        const processedData = processTable1Data(finalData.result.Table1[0]);
        setTable1Data(processedData);
      }

      // Process and set Table2 data
      if (finalData.result.Table2) {
        const processedTable2Data = processTable2Data(finalData.result.Table2);
        setTable2Data(processedTable2Data);
      }

      // Process and set Table4 data
      if (finalData.result.Table4) {
        const processedTable4Data = processGenericTableData(
          finalData.result.Table4
        );
        setTable4Data(processedTable4Data);
        setColumnsTable4(generateColumns(processedTable4Data));
      }

      // Process and set Table3_adds data
      if (finalData.result.Table3_adds) {
        const processedTable3AddsData = processGenericTableData(
          finalData.result.Table3_adds
        );
        setTable3AddsData(processedTable3AddsData);
        setColumnsTable3Adds(generateColumns(processedTable3AddsData));
      }

      // Process and set Table3_removals data
      if (finalData.result.Table3_removals) {
        const processedTable3RemovalsData = processGenericTableData(
          finalData.result.Table3_removals
        );
        setTable3RemovalsData(processedTable3RemovalsData);
        setColumnsTable3Removals(generateColumns(processedTable3RemovalsData));
      }

      // Process and set Table5 data
      if (finalData.result.Table5) {
        const processedTable5Data = processGenericTableData(
          finalData.result.Table5
        );
        setTable5Data(processedTable5Data);
        setColumnsTable5(generateColumns(processedTable5Data));
      }
    }
  }, []);

  return (
    <div className="portfolioDash">
      {/* Main Accordian */}
      <div className="AccordianMainFunding">
        <div className="mainAccordian">Main</div>
        <div className="AccordianFunding">
          <Button
            variant="contained"
            color="primary"
            className="publishFunding"
            // onClick={this.PublishReport}
          >
            Publish
            {/* {this.state.formLoader1 === true ? (
              <CircularProgress size="22px" color="primary" />
            ) : (
              ""
            )} */}
          </Button>
          <img
            src={AccordIcon}
            alt="Accordian"
            className={`publishFundingImg ${
              isAccordian ? "AccordImgGenReverse" : "AccordImgGen"
            }`}
            onClick={handleAccordian}
          />
        </div>
      </div>

      {/* Conditionally render Table 1 and Table 2 */}
      {!isAccordian && (
        <>
          <div className="workbench-table-container">
            <label className="tableHeaders">{tableTitles[0]}</label>
            <div className="dash-table-alignments">
              <Table
                columns={columnsTable1}
                className="performance-table"
                dataSource={table1Data}
                pagination={{ pageSize: 10 }}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>

          <div className="workbench-table-container">
            <label className="tableHeaders">{tableTitles[1]}</label>
            <div className="dash-table-alignments">
              <Table
                columns={columnsTable2}
                className="performance-table"
                dataSource={table2Data}
                pagination={{ pageSize: 10 }}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>

          {/* Render Table3_adds */}
          <div className="workbench-table-container">
            <label className="tableHeaders">{tableTitles[2]}</label>
            <div className="dash-table-alignments">
              <Table
                columns={columnsTable3Adds}
                className="performance-table"
                dataSource={table3AddsData}
                pagination={{ pageSize: 10 }}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>

          {/* Render Table3_removals */}
          <div className="workbench-table-container">
            <label className="tableHeaders">{tableTitles[3]}</label>
            <div className="dash-table-alignments">
              <Table
                columns={columnsTable3Removals}
                className="performance-table"
                dataSource={table3RemovalsData}
                pagination={{ pageSize: 10 }}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>

          {/* Render Table4 */}
          <div className="workbench-table-container">
            <label className="tableHeaders">{tableTitles[4]}</label>
            <div className="dash-table-alignments">
              <Table
                columns={columnsTable4}
                className="performance-table"
                dataSource={table4Data}
                pagination={{ pageSize: 10 }}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>
        </>
      )}

      {/* Restricted Accordian */}
      <div className="AccordianRestrictedFunding">
        <div className="nameAccordianDataCheckRestricted">Restricted</div>
        <div className="AccordianDataCheckRestricted">
          <img
            src={AccordIcon}
            alt="Accordian"
            className={
              isRestrictedAccordian ? "AccordImgGenReverse" : "AccordImgGen"
            }
            onClick={handleRestrictedAccordian}
          />
        </div>
      </div>

      {/* Conditionally render Table5 */}
      {!isRestrictedAccordian && (
        <div className="workbench-table-container fundingRestricted">
          <label className="tableHeaders">{tableTitles[5]}</label>
          <div className="dash-table-alignments">
            <Table
              columns={columnsTable5}
              className="performance-table"
              dataSource={table5Data}
              pagination={{ pageSize: 10 }}
              scroll={{ x: "max-content" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default FundingDash;
