import MUIDataTable from "mui-datatables";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import Header from "../../../../../components/header/header";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  investorGetalldeals,
  getDealDetailsByInvestorPaymentDate,
} from "../../../../../servies/services";
import { TrainRounded } from "@material-ui/icons";
import Loader from "../../../../../components/loader";
import NumberComp2 from "../../../../../components/NumberComp2";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as moment from "moment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ReorderIcon from "@material-ui/icons/Reorder";
import BarChartIcon from "@material-ui/icons/BarChart";
import { data } from "jquery";
import { Servicer } from "../../../../../components/sidebar/menu";
import Iframe from "react-iframe";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ReactModal from "react-modal";
import BeanEater from "../../../../../images/BeanEater.gif";

class IssuerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: sessionStorage.getItem("token"),
      paymentDates: [],
      versionSelected: "",
      versions: [],
      tableData: [],
      IssuerArrays: [],
      TrusteeArrays: [],
      AssetArrays: [],
      Issuer: null, // Initially, no value is selected
    Trustee: null,
    AssetClass: null,
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      loading: false,
      getLoansLoader: false,
      PaymentDate: "",
      screenloader: false,
      LastPaymentDate: "",
      anchorEl: null,
      servicerValues: [],
      isDateSelected: null,
      reorderchart: true,
      searchText: "",
      barchart: false,
      bdbUrl: "",
      showSearchBox: false,
      searchText: "",
      Version: [],
      getlastPaymentDates: [],
      getDashboardVersions: [],
      openModalPayment: false,
      getPaymentDateIndex: null,
      singleRowVersion:{},
      singleRowPaymentDate: {},
    };
  }

  investorGetalldeals = async () => {
    var data = {};
    data.InvestorName = sessionStorage.getItem("userrole");
    data.peer = this.state.peer;

    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await investorGetalldeals(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data,
        manipulatedTableData:APIResponse.data,
        IssuerArrays: [
          ...new Set(APIResponse.data.map((item) => item["Issuer"])),
        ],
        TrusteeArrays: [
          ...new Set(APIResponse.data.map((item) => item["Trustee"])),
        ],
        AssetArrays: [
          ...new Set(APIResponse.data.map((item) => item["Asset Class"])),
        ],
        loading: false,
      });

      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      // variant: "info",
      // autoHideDuration: 3000,
      // });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // Check if the PaymentDate state has changed
    if (
      prevState.PaymentDate !== this.state.PaymentDate ||
      prevState.getPaymentDateIndex !== this.state.getPaymentDateIndex
    ) {
      //|| prevState.singleRowPaymentDate !== this.state.singleRowPaymentDate
      // Perform the API call or other logic here
      const { PaymentDate, singleRowPaymentDate } = this.state;
      console.log("rowData", singleRowPaymentDate);

      this.updatePaymentDateValue(PaymentDate, singleRowPaymentDate);
    }
    // if (
    //   this.state.Issuer !== prevState.Issuer ||
    //   this.state.Trustee !== prevState.Trustee ||
    //   this.state.AssetClass !== prevState.AssetClass
    // ) {
    //   // Update the tableData based on the selected filters
    //   const filteredData = this.filterTableData();
    //   this.setState({ tableData: filteredData });
    // }
  }

  onchange = (e) => {
    e.preventDefault();
    const searchText = e.target.value;
    this.setState({ searchText: searchText });
    console.log("search: e.target.value", this.state.searchText);
  };

  searchBar = () => (
    <div className="search-bar-main-container" id="searchBox">
      <div className="tableSearch1">
        <TextField
          value={this.state.searchText}
          onChange={this.onchange}
          placeholder="Search"
          color="#212121"
          variant="standard"
          size="small"
        />
      </div>
      <button
        type="button"
        onClick={this.onClickCloseSearchBar}
        className="close-mui-icon"
      >
        <CloseIcon />
      </button>
    </div>
  );

  onClickCloseSearchBar = () => {
    this.setState({ searchText: "" });
    this.setState({ showSearchBox: false });
  };
  onClickSearchButton = () => {
    this.setState({ showSearchBox: true });
  };

  async componentDidMount() {
    var component = window.location.pathname;
    // sessionStorage.setItem("component", component);
    // sessionStorage.removeItem('selectservicerdate');
    // sessionStorage.removeItem('servicerdealname');
    // sessionStorage.removeItem('servicerdashboardname');
    // sessionStorage.removeItem("dealname");
    this.investorGetalldeals();
  }
  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            borderBottom: "none !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
          },
          toolButton: {
            fontFamily: "Catamaran !important",
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            // padding: "0px 20px !important",
            // width:"20rem",
            fontWeight: "600 !important",
            fontFamily: "Mulish !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },
        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
        MuiInput: {
          root: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
            padding: "5px !important",
          },
          underline: {
            "&:after": {
              borderBottom: "none !important",
            },
            "&:before": {
              borderBottom: "none !important",
            },
          },
        },
      },
    });

  Recurringreport = (value, tableMeta) => {
    console.log(tableMeta.rowData);
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem(
      "nextpaymentdate",
      this.state.PaymentDate === ""
        ? tableMeta.rowData[2][0]
        : this.state.PaymentDate
    );
    sessionStorage.setItem(
      "version",
      this.state.versionSelected === ""
        ? tableMeta.rowData[3][0]
        : this.state.versionSelected
    );
    sessionStorage.setItem(
      "getDashboardDeals",
      JSON.stringify(this.state.tableData.map((item) => item["Deal Name"]))
    );
    sessionStorage.setItem(
      "getDashboardDates",
      JSON.stringify(tableMeta.rowData[2])
    );
    sessionStorage.setItem(
      "getDashboardVersions",
      JSON.stringify(tableMeta.rowData[3])
    );
    // sessionStorage.setItem("lastpaymentdate", tableMeta.rowData[3]);
    this.props.history.push({
      pathname: "/issuer/report_IssuerRecurring",
    });
  };

  handleClickReorder = () => {
    this.setState({
      reorderchart: true,
      barchart: false,
    });
  };

  handleClickbarchart = () => {
    this.setState({
      reorderchart: false,
      barchart: true,
    });
  };

  bdbapi = async () => {
    let x = moment(this.state.NextPaymentDate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(8, 10);
    function getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);

      return date.toLocaleString("en-US", { month: "short" });
    }

    const getMonth = getMonthName(month); // Jan

    let poolidold = JSON.stringify({
      "Deal Name": {
        type: "in",
        value: [this.state.DealName],
      },
      MonthandYear: {
        type: "in",
        value: [`${getMonth}-${year}`],
      },
    });

    let mailid = this.state.Email;
    let password = this.state.Password;
    const res = await axios.get(
      process.env.react_app_base_url +
        "backendapi_wsfs_bdb/IA/link?" +
        "mailid=" +
        mailid +
        "&password=" +
        password
    );
    if (res.status == 204) {
      this.setState({ bdbUrl: "UrlBdbNew", bdb_loader: false });
    } else {
      let UrlBdbNew =
      "https://analytics.intainabs.com/home/#/opendocument?data=" + res.data;
      this.setState({ bdbUrl: UrlBdbNew, bdb_loader: false });
    }
  };

  handlePaymentDateChange = async (event, value, rowIndex, tableData) => {
    const newValue = event.target.value;
    console.log("newValue", newValue);
    const updatedPaymentDates = [...this.state.paymentDates];
    updatedPaymentDates[rowIndex] = newValue;

    // console.log("PaymentDates", value);

    console.log("updatedPaymentDates", updatedPaymentDates);

    this.setState(
      {
        getlastPaymentDates: value,
        isDateChanged: true,
        getPaymentDateIndex: rowIndex,
        PaymentDate: newValue,
        openModalPayment: true,
        paymentDates: updatedPaymentDates,
        singleRowPaymentDate: tableData,
      },
      () => console.log("rowData", this.state.singleRowPaymentDate)
    );
  };

  handleVersionChange = async (event, value, tableData) => {
    const newValue = event.target.value;
    console.log("newValue", newValue);

    const updatedVersions = [...this.state.versions];
    updatedVersions[tableData[3]] = newValue;

    console.log("updatedVersions",tableData[3], updatedVersions);

    this.setState({
      versionSelected: updatedVersions[tableData[3]],
      versions: updatedVersions,
      singleRowVersion: tableData,
      getDashboardVersions: value,
    });
  };
  updatePaymentDateValue = async (newValue, rowData) => {
    console.log("newValue", newValue, rowData);
    var data = {};
    var input = {};
    input["Deal Name"] = rowData[1];
    input["Payment Date"] = rowData[2];
    input["Version"] = rowData[3];
    input["Asset Class"] = rowData[4];
    input["Issuer"] = rowData[5];
    input["Trustee"] = rowData[6];
    console.log("input", input);
    data.input = input;
    data.selectedDate = newValue;
    data.peers = this.state.peers;
    console.log("data", data);
    // this.setState({ PaymentDate: newValue });
    this.setState({ singleRowPaymentDate: rowData });
    // this.setState({ dateNeeded: rowData });

    const APIResponse = await getDealDetailsByInvestorPaymentDate(data);
    console.log("APIResponse", APIResponse);

    if (APIResponse.status === 200) {
      const res = APIResponse.data;
      const modifiedData = this.state.tableData.map((item, index) => {
        if (item["Deal Name"] === rowData[1]) {
        //   item["Payment Date"] = res["Payment Date"];
        // const version = res["Version"];
        // console.log(">>>>>>>>>>>>>>>",version);
        // this.setState({versions:version})
          return res;
        } else {
          return item;
        }
      });
      console.log("modifiedData", modifiedData);
      this.setState(
        {
          getLoansLoader: false,
          tableData: modifiedData,
          loading: false,
          openModalPayment: false,
        },
        () => {
          console.log("singleRowPaymentDate", this.state.tableData);
        }
      );

      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
      });
      console.log("", this.state.PaymentDate);

      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleSelectChange = (event, filterType) => {
    console.log("<<<<<", event.target.value);
    this.setState(
      {
        [filterType]: event.target.value,
      },
      () => {
        this.filterTableData();
      }
    );
  };
  
  filterTableData = () => {
    const { tableData, manipulatedTableData, Issuer, Trustee, AssetClass } = this.state;
  
    console.log({ Issuer, Trustee, AssetClass });
  
    // Filter data based on selected filters
    let filteredData = manipulatedTableData;
  
    if (Issuer) {
      filteredData = filteredData.filter((item) => item["Issuer"] === Issuer);
    }
  
    if (Trustee) {
      filteredData = filteredData.filter((item) => item["Trustee"] === Trustee);
    }
  
    if (AssetClass) {
      filteredData = filteredData.filter((item) => item["AssetClass"] === AssetClass);
    }
  
    this.setState({ tableData: filteredData });
  };
  

  render() {
    console.log("tabledata",this.state.tableData);
    console.log("IssuerAr",this.state.IssuerArrays);
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      searchText: this.state.searchText,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      onRowHover: this.onRowHover,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: TrainRounded,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        const lowercaseSearchQuery = searchQuery.toLowerCase();
      
        // Assuming the "Deal Name" column is the first column (index 0)
        const dealName = currentRow[0] !== undefined ? currentRow[0].toString().toLowerCase() : '';
      
        // Check if the "Deal Name" contains the search query
        const isFound = dealName.includes(lowercaseSearchQuery);
      
        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Deal Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };

    // const getAllDates = (date, Index, tableMeta) => {
    // console.log("date", date, Index, tableMeta);
    // const changeDateFormat =
    // Index === tableMeta.rowIndex
    // ? moment(date).format("MM/DD/YYYY").toString()
    // : null;
    // this.setState({ dateNeeded: Index });
    // console.log("changeDateFormat", changeDateFormat);
    // return changeDateFormat;
    // };

    // const getIndex = (index) => {
    // console.log("index", index);
    // this.setState({ isDateSelected: index });
    // return index;
    // };

    const columns = [
      {
        name: "Deal Name",
        label: "Sr.No",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <React.Fragment>{tableMeta.rowIndex + 1}</React.Fragment>;
          },
        },
      },
      {
        name: "Deal Name",
        label: "Deal Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Payment Date",
        label: "Payment Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div>
                  <select
                    className="input-select-general-new2"
                    value={this.state.paymentDates[tableMeta.rowIndex]}
                    onChange={(e) =>
                      this.handlePaymentDateChange(
                        e,
                        value,
                        tableMeta.rowIndex,
                        tableMeta.rowData
                      )
                    }
                  >
                    {value.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Version",
        label: "Version",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log("tabe meta", tableMeta.rowData[3],tableMeta.rowIndex);
            return (
              <React.Fragment>
                {value.every((item) => item !== "") ? (
                  <select
                    className="input-select-general-new2"
                    value={this.state.versions[tableMeta.rowIndex]}
                    onChange={(e) =>
                      this.handleVersionChange(e, value, tableMeta.rowData)
                    }
                  >
                    {value.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select
                    className="input-select-dashboard"
                    disabled
                    // value={value}
                    // onChange={handleVersionChange}
                  >
                    {value.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                )}
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Asset Class",
        label: "Asset Class",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Issuer",
        label: "Issuer",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Trustee",
        label: "Trustee",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Deal Name",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                paddingTop: "5px",
                paddingLeft: "2rem",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            console.log("tabe meta", tableMeta.rowData[5]);
            return (
              <React.Fragment>
                <div className="align-actions-investor">
                  <button
                    className="popupdashboard"
                    style={{ paddingRight: "1rem" }}
                    onClick={() => this.Recurringreport(value, tableMeta)}
                  >
                    View Report
                  </button>
                  {/* </Button> */}
                </div>
              </React.Fragment>
            );
          },
        },
      },
    ];
    return (
      <div className="page">
        <Sidebar1 activeComponent={"IssuerDashBoard"} />
        <div className="content">
          <div className="header">
            <Header></Header>
          </div>
          <div className="row1">
            <div
              className="col-5 col-sm-6 col-md-3 d-flex hellocard"
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1.5rem",
                width: "90rem",
                marginLeft: "25px",
              }}
            >
              <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                {this.state.showSearchBox == true ? (
                  this.searchBar()
                ) : (
                  <h3 className="headerdashboard">Trustee's Investor Report</h3>
                )}
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onClickSearchButton}
                style={{ display: "flex", marginRight: "30px" }}
              >
                Search
                {this.state.formLoader === true ? (
                  <CircularProgress size="22px" color="primary" />
                ) : (
                  ""
                )}
              </Button>
            </div>
          </div>
          <div className="row row14">
            <div className="general-InvContainer">
              <div>
                <label className="label">Issuer</label>
                <select
                  className="input-select-general-new1"
                  value={this.state.Issuer}
                  onChange={(event) => this.handleSelectChange(event, "Issuer")}
                >
                  <option value="">Select any one</option>
                  {this.state.IssuerArrays.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="general-InvContainer">
              <div>
                <label className="label">Trustee</label>
                <select
                  className="input-select-general-new1"
                  value={this.state.Trustee}
                  onChange={(event) =>
                    this.handleSelectChange(event, "Trustee")
                  }
                >
                  <option value="">Select any one</option>
                  {this.state.TrusteeArrays.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="general-InvContainer">
              <div>
                <label className="label">AssetClass</label>
                <select
                  className="input-select-general-new1"
                  value={this.state.AssetClass}
                  onChange={(event) =>
                    this.handleSelectChange(event, "Asset Class")
                  }
                >
                  <option value="">Select any one</option>
                  {this.state.AssetArrays.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "-45px" }}>
            <div style={{ marginTop: "50px" }}></div>
            <div style={{ marginTop: "-30px" }}>
              <div className="page-content">
                <React.Fragment>
                  {this.state.reorderchart == true ? (
                    <div className="workbench-table-container">
                      <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          // title={'Dashboard'}
                          data={this.state.tableData}
                          columns={columns}
                          options={options}
                        />
                      </MuiThemeProvider>
                    </div>
                  ) : (
                    <div className="workbench-table-container">
                      <React.Fragment></React.Fragment>
                    </div>
                  )}
                </React.Fragment>
              </div>
            </div>
          </div>

          <ReactModal
            isOpen={this.state.openModalPayment}
            contentLabel="Minimal Modal Example"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed", // Use fixed positioning for the overlay
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 9999, // Set a high z-index to ensure the modal appears on top
              },
              content: {
                position: "absolute",
                background: "transparent", // Make the modal content transparent
                border: "none", // Optional: Remove any borders
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                overflow: "hidden",
              },
            }}
          >
            <React.Fragment>
              <img
                src={BeanEater}
                style={{
                  width: "8vw",
                  height: "9vh",
                  backgroundColor: "transparent",
                }}
              />
              <h3 className="dot-loader">Loading.Please wait</h3>
            </React.Fragment>
          </ReactModal>
        </div>
      </div>
    );
  }
}

export default withSnackbar(IssuerDashboard);
