/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Header from "../../../../../components/header/index.js";
import Sidebar1 from "../../../../../components/sidebar/sidebar.js";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import { TrainRounded } from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../../../components/loader/index.js";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import axios from "axios";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  GetDataByDefaultValue,
  GetDataByUpdateAsset,
  GetDataByUpdateIssuer,
  GetDataByTableDataChecks,
} from "../../../../../servies/services.js";
import LinearLoader from "../../../../../components/loader/LinearLoader.js";
import * as moment from "moment";
import MomentUtils from "@date-io/moment";
import Iframe from "react-iframe";
import Plot from "react-plotly.js";
import NumberComp2 from "../../../../../components/NumberComp2.js";
import { MultiSelect } from "react-multi-select-component";
import Select, { components } from "react-select";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import DataDisplaySummary from "./DataDisplaySummary.js";
import IDA from "../../../../../images/IDA.svg";
import ChatAssistant from "./ChatAssistant.js";
import DataCheckDash from "./DataCheckDash/DataCheckDash.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import { format } from "date-fns";

// Custom dropdown indicator component
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <FaChevronUp /> : <FaChevronDown />}
    </components.DropdownIndicator>
  );
};

const customValueRenderer = (selected) => {
  if (selected.length === 0) {
    return "Select one or more";
  } else {
    return selected.map(({ label }) => label).join(", ");
  }
};

class DataChecksDealAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      getLoansLoader: false,
      openPopup: false,
      searchText: "",
      showDatepicker: false,
      showApplyChange: false,
      startDate: new Date("2023-09-01"), // Setting startDate to May 1, 2024
      endDate: new Date("2023-11-20"),
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      AssetClass: [],
      assetClassOptions: [],
      selectedAssetClass: [], // Selected option for react-select
      IssuerGroup: [],
      issuerOptions: [], // New state for Issuer options
      selectedIssuer: [""], // New state for selected Issuers
      dealsOptions: [], // New state for Deals options
      selectedDeals: [],
      DealName: sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Email: sessionStorage.getItem("emailid"),
      Password: sessionStorage.getItem("Pass"),
      TableName: "General",
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      rowsSelected: null,
      investorTab1: false,
      investorTab2: false,
      investorTab3: false,
      investorTab4: true,
      screenloader: false,
      pooldetails: {},
      bdbUrl: "",
      formData1: {
        "First Payment Date": "",
      },
    };
  }
  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  handleClickFundingReports = () => {
    this.setState({
      investorTab1: true,
      investorTab2: false,
      investorTab3: false,
      investorTab4: false,
    });
    window.location.assign("/investor/dealAnalytics/fundingReports");
  };

  handleClickMonthlyReports = () => {
    this.setState({
      investorTab1: false,
      investorTab2: true,
      investorTab3: false,
      investorTab4: false,
    });
    window.location.assign("/investor/dealAnalytics/monthlyReports");
  };

  handleClickAssetReview = () => {
    this.setState({
      investorTab1: false,
      investorTab2: false,
      investorTab3: true,
      investorTab4: false,
    });
    window.location.assign("/investor/dealAnalytics/assetReview");
  };
  handleClickDataChecks = () => {
    this.setState({
      investorTab1: false,
      investorTab2: false,
      investorTab3: false,
      investorTab4: true,
    });
    window.location.assign("/investor/dealAnalytics/dataChecks");
  };

  toggleDatepicker = () => {
    this.setState((prevState) => ({
      showDatepicker: !prevState.showDatepicker,
    }));
  };

  handleStartDateChange = (date) => {
    this.setState((prevState) => ({
      startDate: date,
      endDate:
        prevState.endDate && date > prevState.endDate
          ? null
          : prevState.endDate,
    }));
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  toggleDatepicker = () => {
    this.setState((prevState) => ({
      showDatepicker: !prevState.showDatepicker,
    }));
  };

  applyDateRange = () => {
    this.setState((prevState) => ({
      showApplyChange: !prevState.showApplyChange,
    }));
    console.log("endDate", this.state.endDate);
  };

  formatDate = (date) => {
    try {
      if (!date || isNaN(new Date(date))) return "";
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
      }).format(new Date(date));
    } catch (error) {
      console.error("Error formatting date:", error, date);
      return "";
    }
  };

  formatDateForData = (date) => {
    try {
      if (!date || isNaN(new Date(date))) return "";
      return format(new Date(date), "yyyy-MM-dd");
    } catch (error) {
      console.error("Error formatting date for data:", error, date);
      return "";
    }
  };

  handleSelectChange(selectedOption) {
    this.setState({ selectedAssetClass: selectedOption });
  }

  handleIssuerChange = (selectedOptions) => {
    this.setState({ selectedIssuer: selectedOptions });
  };

  handleDealsChange = (selectedOptions) => {
    this.setState({ selectedDeals: selectedOptions });
  };

  GetDataByDefaultValue = async () => {
    var data = {};
    data.UserId = sessionStorage.getItem("user_id");
    data.UserName = sessionStorage.getItem("user_name");

    console.log("datata", data);
    this.setState({ screenloader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByDefaultValue(data);
    // console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      const assetClassArray = APIResponse.data.result.asset_class;
      const assetClassOptions = assetClassArray.map((option) => ({
        value: option,
        label: option,
      }));

      // Set all asset classes as selected by default
      const allAssetClassesSelected = assetClassOptions;

      const issuerArray = APIResponse.data.result.issuer_group;
      const issuerOptions = issuerArray.map((option) => ({
        value: option,
        label: option,
      }));

      // Set all issuers as selected by default
      const allIssuersSelected = issuerOptions;

      // Assuming deals data is in APIResponse.data.result.deals
      const dealsArray = APIResponse.data.result.deal_type;
      const dealsOptions = dealsArray.map((deal) => {
        // Extract the key and value from each deal object
        const [key, value] = Object.entries(deal)[0];
        return { value: key, label: value };
      });

      // Set all deals as selected by default
      const allDealsSelected = dealsOptions; // Include all deals

      this.setState({
        getLoansLoader: false,
        AssetClass: assetClassArray,
        assetClassOptions: assetClassOptions,
        IssuerGroup: issuerArray,
        issuerOptions: issuerOptions, // Set issuer options
        Deals: dealsArray,
        dealsOptions: dealsOptions,
        selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
        selectedIssuer: allIssuersSelected, // Set all issuers as selected
        selectedDeals: allDealsSelected, // Set all deals as selected
        loading: false,
      });
    } else {
      this.setState({ screenloader: false, loading: false });
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByUpdateAsset = async () => {
    var data = {};
    data.UserName = sessionStorage.getItem("user_name");
    // Ensure selectedAssetClass is an array before using map
    const selectedAssetClass = Array.isArray(this.state.selectedAssetClass)
      ? this.state.selectedAssetClass
      : [];

    data.asset_class = selectedAssetClass.map((item) => item.value);
    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByUpdateAsset(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      const issuerArray = APIResponse.data.result.issuer_group;
      const issuerOptions = issuerArray.map((option) => ({
        value: option,
        label: option,
      }));

      this.setState({
        getLoansLoader: false,
        // AssetClass: assetClassArray,
        // assetClassOptions: assetClassOptions,
        IssuerGroup: issuerArray,
        issuerOptions: issuerOptions, // Set issuer options
        // Deals: dealsArray,
        // dealsOptions: dealsOptions,
        // selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
        // selectedIssuer: allIssuersSelected, // Set all issuers as selected
        // selectedDeals: allDealsSelected, // Set all deals as selected
        loading: false,
      });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByUpdateIssuer = async () => {
    var data = {};
    data.UserName = sessionStorage.getItem("user_name");
    // Ensure selectedAssetClass is an array before using map
    const selectedIssuer = Array.isArray(this.state.selectedIssuer)
      ? this.state.selectedIssuer
      : [];

    data.issuer_group = selectedIssuer.map((item) => item.value);
    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByUpdateIssuer(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      // Assuming deals data is in APIResponse.data.result.deals
      const dealsArray = APIResponse.data.result.deal_type;
      const dealsOptions = dealsArray.map((deal) => {
        // Extract the key and value from each deal object
        const [key, value] = Object.entries(deal)[0];
        return { value: key, label: value };
      });

      this.setState({
        getLoansLoader: false,
        // AssetClass: assetClassArray,
        // assetClassOptions: assetClassOptions,
        // IssuerGroup: issuerArray,
        // issuerOptions: issuerOptions, // Set issuer options
        Deals: dealsArray,
        dealsOptions: dealsOptions,
        // selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
        // selectedIssuer: allIssuersSelected, // Set all issuers as selected
        // selectedDeals: allDealsSelected, // Set all deals as selected
        loading: false,
        screenloader: false,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
      });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByTableDataChecks = async () => {
    const { selectedDeals, Deals, startDate, endDate } = this.state;

    // Map selected deals back to their original structure
    const selectedDealsOriginal = selectedDeals.map((selectedDeal) => {
      const originalDeal = Deals.find(
        (deal) => Object.keys(deal)[0] === selectedDeal.value
      );
      return originalDeal;
    });

    var data = {};
    const selectedAssetClass = Array.isArray(this.state.selectedAssetClass)
      ? this.state.selectedAssetClass
      : [];

    // Ensure selectedAssetClass is an array before using map
    const selectedIssuer = Array.isArray(this.state.selectedIssuer)
      ? this.state.selectedIssuer
      : [];

    const formattedStartDate = this.formatDateForData(startDate);
    const formattedEndDate = this.formatDateForData(endDate);
    console.log("formattedStartDate", formattedStartDate, formattedEndDate);

    data.asset_class = [
      "Residential Real Estate"
    ];
    // selectedAssetClass.map((item) => item.value);
    data.issuer_group = ["JPMorgan Chase Bank, N.A"];
    //  selectedIssuer.map((item) => item.value);
    data.deal_type = selectedDealsOriginal;
    data.start_date = formattedStartDate;
    data.end_date = formattedEndDate;
    console.log("datata", data);
    this.setState({ screenloader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByTableDataChecks(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        screenloader: false,
      });
      sessionStorage.setItem(
        "finalDataDataChecks",
        JSON.stringify(APIResponse.data.result)
      );
      // console.log(">>>>>>>>>>>>>", APIResponse.data.result.table);
      // sessionStorage.setItem(
      //   "finalDataTableSummary",
      //   JSON.stringify(APIResponse.data.result.table)
      // );
    } else {
      this.setState({ screenloader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    await this.GetDataByDefaultValue();
  }

  async componentDidUpdate(prevProps, prevState) {
    // Handle initial null state and ensure arrays before mapping for selectedAssetClass
    const prevAssetValues = prevState.selectedAssetClass
      ? prevState.selectedAssetClass.map((item) => item.value)
      : [];
    const currentAssetValues = this.state.selectedAssetClass
      ? this.state.selectedAssetClass.map((item) => item.value)
      : [];

    // Handle initial null state and ensure arrays before mapping for selectedIssuer
    const prevIssuerValues = prevState.selectedIssuer
      ? prevState.selectedIssuer.map((item) => item.value)
      : [];
    const currentIssuerValues = this.state.selectedIssuer
      ? this.state.selectedIssuer.map((item) => item.value)
      : [];

    // Handle initial null state and ensure arrays before mapping for selectedDeals
    const prevDealsValues = prevState.selectedDeals
      ? prevState.selectedDeals.map((item) => item.value)
      : [];
    const currentDealsValues = this.state.selectedDeals
      ? this.state.selectedDeals.map((item) => item.value)
      : [];

    // Check if the selectedAssetClass values have changed
    if (
      JSON.stringify(prevAssetValues) !== JSON.stringify(currentAssetValues)
    ) {
      await this.GetDataByUpdateAsset();
    }

    // Check if the selectedIssuer values have changed
    if (
      JSON.stringify(prevIssuerValues) !== JSON.stringify(currentIssuerValues)
    ) {
      await this.GetDataByUpdateIssuer();
    }

    // // Check if the selectedDeals values have changed
    if (
      JSON.stringify(prevDealsValues) !== JSON.stringify(currentDealsValues)
    ) {
      await this.GetDataByTableDataChecks();
    }
    if (prevState.showApplyChange !== this.state.showApplyChange) {
      await this.GetDataByTableDataChecks();
    }
  }

  render() {
    const { showDatepicker, startDate, endDate } = this.state;

    const customStyles = {
      control: (provided) => ({
        ...provided,
        border: "1px solid black",
        borderRadius: "8px",
        minHeight: "38px",
        boxShadow: "none",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "#adaeaa",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "#000000",
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Ensures the dropdown is above other elements
      }),
      indicatorSeparator: (provided) => ({
        display: "none", // Removes the vertical line
      }),
    };

    return (
      <React.Fragment>
        <div className="">
          <Sidebar1 activeComponent={"InvestorDealAnalytics"} />
          <div className="content-bdb">
            <div className="header">{/* <Header></Header> */}</div>
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <>
                <div className="page-contentofpool1_Inv">
                  <div className="row1">
                    <div
                      className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "1.5rem",
                        width: "90rem",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h3 className="headerdashboard">DEAL</h3>
                      </div>
                    </div>
                  </div>

                  <div className="dropdown-row">
                    <div className="dropdown-container">
                      <label className="dropdown-label">Asset Class:</label>
                      <div className="dropdown-content">
                        <Select
                          options={this.state.assetClassOptions}
                          value={this.state.selectedAssetClass}
                          onChange={this.handleSelectChange}
                          isClearable
                          placeholder="Select any one"
                          styles={customStyles}
                          components={{ DropdownIndicator }}
                        />
                      </div>
                    </div>

                    <div className="dropdown-container">
                      <label className="dropdown-label">Issuer:</label>
                      <div className="dropdown-content">
                        <MultiSelect
                          options={this.state.issuerOptions}
                          value={this.state.selectedIssuer}
                          onChange={this.handleIssuerChange}
                          valueRenderer={customValueRenderer}
                          labelledBy="Select"
                          className="custom-multiselect"
                        />
                      </div>
                    </div>

                    <div className="dropdown-container">
                      <label className="dropdown-label">Deals:</label>
                      <div className="dropdown-content">
                        <MultiSelect
                          options={this.state.dealsOptions}
                          value={this.state.selectedDeals}
                          onChange={this.handleDealsChange}
                          valueRenderer={customValueRenderer}
                          labelledBy="Select"
                          className="custom-multiselect"
                        />
                      </div>
                    </div>

                    <div className="dropdown-container">
                      <label className="dropdown-label">Date:</label>
                      <div className="dropdown-content">
                        <Select
                          // options={this.state.assetClassOptions}
                          // value={this.state.selectedAssetClass}
                          // onChange={this.handleSelectChange}
                          isClearable
                          placeholder="Select any one"
                          styles={customStyles}
                          components={{ DropdownIndicator }}
                        />
                      </div>
                    </div>
                  </div>

                  <DataDisplaySummary />

                  <div className="row1 button-group-container MoveTabs">
                    <div className="col-7 col-sm-6 col-md-7 hellocard">
                      <button
                        type="button"
                        onClick={() => this.handleClickFundingReports()}
                        className={
                          this.state.investorTab1 == true
                            ? "issuerDashboard-table-top-button-insights-active-analytics"
                            : "issuerDashboard-table-top-button-insights-analytics"
                        }
                      >
                        Funding Reports
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickMonthlyReports()}
                        className={
                          this.state.investorTab2 == true
                            ? "issuerDashboard-table-top-button-insights-active-analytics"
                            : "issuerDashboard-table-top-button-insights-analytics"
                        }
                      >
                        Monthly Reports
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickAssetReview()}
                        className={
                          this.state.investorTab3 == true
                            ? "issuerDashboard-table-top-button-insights-active-analytics"
                            : "issuerDashboard-table-top-button-insights-analytics"
                        }
                      >
                        Asset Review
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickDataChecks()}
                        className={
                          this.state.investorTab4 == true
                            ? "issuerDashboard-table-top-button-insights-active-analytics"
                            : "issuerDashboard-table-top-button-insights-analytics"
                        }
                      >
                        Data Checks
                      </button>
                    </div>
                    <div className="IDA_Virtual">
                      <img src={IDA} alt="VirtualAsst" />
                      <ChatAssistant />
                    </div>
                  </div>
                  <div className="scrollable-content-dataChecks workbench-table-container">
                    {/* <div className="date-align-left">
                      <div className="align-perf-dropdowns">
                        <div className="date-range-picker">
                          <h6 className="timePeriod">Time Period : </h6>
                          <div className="align-per-tabs">
                            <div className="date-input-container">
                              <input
                                type="text"
                                readOnly
                                className="date-input"
                                onClick={this.toggleDatepicker}
                                placeholder="Select Date Range"
                                value={
                                  startDate && endDate
                                    ? `${this.formatDate(
                                        startDate
                                      )} - ${this.formatDate(endDate)}`
                                    : startDate
                                    ? `${this.formatDate(startDate)}`
                                    : endDate
                                    ? `${this.formatDate(endDate)}`
                                    : ""
                                }
                              />
                              <FaRegCalendarAlt
                                className="calendar-icon"
                                onClick={this.toggleDatepicker}
                              />
                            </div>

                            {showDatepicker && (
                              <div className="datepicker">
                                <span>Select the Date Range</span>
                                <div
                                  className="datepicker-row"
                                  style={{ marginTop: "20px" }}
                                >
                                  <label className="datepicker-label">
                                    From:
                                  </label>
                                  <DatePicker
                                    selected={startDate}
                                    onChange={this.handleStartDateChange}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Select Start Date"
                                    className="date-picker"
                                  />
                                </div>
                                <div className="datepicker-row">
                                  <label className="datepicker-label">
                                    To:
                                  </label>
                                  <DatePicker
                                    selected={endDate}
                                    onChange={this.handleEndDateChange}
                                    selectsEnd
                                    showYearDropdown
                                    dropdownMode="select"
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    placeholderText="Select End Date"
                                    className="date-picker"
                                  />
                                </div>
                                <div className="button-row">
                                  <button
                                    className="daterange-btn"
                                    onClick={this.applyDateRange}
                                  >
                                    Apply
                                  </button>
                                  <button
                                    className="daterange-cancelbtn"
                                    onClick={() =>
                                      this.setState({ showDatepicker: false })
                                    }
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <DataCheckDash />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(DataChecksDealAnalytics);
