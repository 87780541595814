/* eslint-disable require-jsdoc */
import React, { Component } from "react";
// import { connect } from 'react-redux';
import Header from "../../../../../components/header";
import Sidebar from "../../../../../components/sidebar";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import "react-input-range/lib/css/index.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  QueryDatatapes,
  dealUploadLoanTape,
  dealUploadLoanTapeprocessing,
  SaveLoanProcessDate,
} from "../../../../../servies/services";
import * as moment from "moment";

class UploadLoanTape extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: false,
      loading: false,
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      screendisplay: true,
      peer: sessionStorage.getItem("peer"),
      servicerPaymentDate:
        this.props.location?.state?.ServicerPaymentDate === undefined
          ? sessionStorage.getItem("selectservicerdate")
          : this.props.location?.state?.ServicerPaymentDate,
      servicerDealName:
        this.props.location?.state?.ServicerDealName === undefined
          ? sessionStorage.getItem("servicerdealname")
          : this.props.location?.state?.ServicerDealName,
      // detailsDate:
      //   this.props.location?.state?.detailsView === undefined
      //     ? sessionStorage.getItem("selectdate")
      //     : this.props.location?.state?.detailsView,
      detailsDate:
        sessionStorage.getItem("selectdate") === null
          ? sessionStorage.getItem("selectservicerdate")
          : sessionStorage.getItem("selectdate"),
      files: [],
      file1: "",
      file2: "",
      DealName:
        this.props.location?.state?.dealname === undefined
          ? sessionStorage.getItem("dealname")
          : this.props.location?.state?.dealname,
      ServicerName:
        this.props.location?.state?.servicer === undefined
          ? sessionStorage.getItem("Servicer")
          : this.props.location?.state?.servicer,
      servicerDashboardName:
        this.props.location?.state?.ServicerDashboardName === undefined
          ? sessionStorage.getItem("servicerdashboardname")
          : this.props.location?.state?.ServicerDashboardName,
      getdashboarddates: sessionStorage.getItem("getDashboardDates"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      uploadDisabled:false,
    };
  }
  handleOnChange1 = (e) => {
    this.setState({
      file1: e.target.files[0],
      filename1: e.target.files[0].name,
    });
    console.log("eeee", e.target.files[0].name);
    console.log("file1", e.target.files[0]);
  };

  handleClickUploadLoanTapes = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
    });
  };

  handleClickPreviewLoanTape = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
    });
    window.location.assign("/admin/previewloantapedetails");
  };
  handleClickMapFields = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
    });
    window.location.assign("/admin/mapfields");
  };
  handleClickPreviewMappedFields = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
    });
    window.location.assign("/admin/previewmapfields");
  };
  handleClickSummarize = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
    });
    window.location.assign("/admin/summarize");
  };

  goBackToDashBoard = () => {
    if (sessionStorage.getItem("user_name") === "Trustee") {
      this.props.history.push({
        pathname: "/admin/viewdetails",
        state: {
          details: this.state.detailsDate,
          dealName: this.state.DealName,
        },
      });
      sessionStorage.setItem("isClosingTape", false);
    } else if (
      sessionStorage.getItem("user_name") === "AdminAG" ||
      sessionStorage.getItem("user_name") === "Trustee"
    ) {
      this.props.history.push({
        pathname: "/admin/viewdetails",
        state: {
          details: this.state.detailsDate,
          dealName: this.state.DealName,
        },
      });
      sessionStorage.setItem("isClosingTape", false);
    } else {
      this.props.history.push({
        pathname: "/dashboard/servicer",
      });
      sessionStorage.setItem("isClosingTape", false);
    }
  };

  onSubmit1 = (e) => {
    e.preventDefault();
    this.dealUploadLoanTape();
  };

  dealUploadLoanTape = async () => {
    const checkDate = 
    sessionStorage.getItem("user_name") === "AdminAG" || 
    sessionStorage.getItem("user_name") === "Trustee" 
    ? this.state.detailsDate
    : this.state.servicerPaymentDate ;
    const checkDealName =
    sessionStorage.getItem("user_name") === "AdminAG" || sessionStorage.getItem("user_name") === "Trustee"
    ? this.state.DealName
    : this.state.servicerDealName || this.state.servicerDashboardName;
    const checkServicerName =
      this.state.ServicerName === null
        ? this.state.servicerDashboardName
        : this.state.ServicerName;
    let x = moment(checkDate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    console.log("uplaodpooluw");
    const newdata = new FormData();
    newdata.append("DealName", checkDealName);
    newdata.append("Month", month);
    newdata.append("Year", year);
    newdata.append("ServicerName", checkServicerName);
    this.state.files.forEach((file) => {
      newdata.append("File", file.file);
    });

    console.log("newdata", newdata);
    this.setState({ formLoader: true });

    const APIResponse = await dealUploadLoanTape(newdata);

    if (APIResponse.status === 200) {
      console.log("upload--", APIResponse);
      if (APIResponse.data.isSuccess === true) {
        this.setState({ formLoader: true });
        const message = APIResponse.data.result;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 2000,
        });
        this.SaveLoanProcessDate();
      } else {
        this.setState({ formLoader: false });
        const message = APIResponse.data.result;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    } else {
      alert("Failed upload");
    }
  };

  onSubmit2 = (e) => {
    e.preventDefault();
    this.dealUploadLoanTapeprocessing();
  };

  dealUploadLoanTapeprocessing = async (index) => {
    const checkDate = 
  sessionStorage.getItem("user_name") === "AdminAG" || 
  sessionStorage.getItem("user_name") === "Trustee" 
  ? this.state.detailsDate
  : this.state.servicerPaymentDate;

    const checkDealName =
    sessionStorage.getItem("user_name") === "AdminAG" || 
    sessionStorage.getItem("user_name") === "Trustee" 
      ? this.state.DealName
      : this.state.servicerDealName || this.state.servicerDashboardName;
    const checkServicerName =
      this.state.ServicerName === null
        ? this.state.servicerDashboardName
        : this.state.ServicerName;
    let x = moment(checkDate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    console.log("uplaodpooluw");
    const newdata = new FormData();
    newdata.append("DealName", checkDealName);
    newdata.append("Month", month);
    newdata.append("Year", year);
    newdata.append("ServicerName", checkServicerName);
    this.state.files.forEach((file) => {
      newdata.append("File", file.file);
      newdata.append("PrimaryKey", file.primaryKey);
    });

    console.log("newdata", newdata);
    this.setState({ formLoader: true });

    const APIResponse = await dealUploadLoanTapeprocessing(newdata);

    if (APIResponse.status === 200) {
      console.log("upload--", APIResponse);
      if (APIResponse.data.isSuccess === true) {
        this.setState({ formLoader: true });
        const message = APIResponse.data.result;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 2000,
        });
        this.SaveLoanProcessDate();
      } else {
        this.setState({ formLoader: false });
        const message = APIResponse.data.result;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    } else {
      alert("Failed upload");
    }
  };
  SaveLoanProcessDate = async () => {
    const checkDate =
    sessionStorage.getItem("user_name") === "AdminAG" || 
    sessionStorage.getItem("user_name") === "Trustee" 
    ? this.state.detailsDate
    : this.state.servicerPaymentDate;
    const checkDealName =
      this.state.DealName === null
        ? this.state.servicerDealName
        : this.state.DealName;
    const checkServicerName =
      this.state.ServicerName === null
        ? this.state.servicerDashboardName
        : this.state.ServicerName;
    let x = moment(checkDate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = checkDealName;
    data.Month = month;
    data.Year = year;
    data.ServicerName = checkServicerName;
    data.peers = this.state.peers;

    console.log("datata", data);
    this.setState({ formLoader: true });
    const APIResponse = await SaveLoanProcessDate(data);

    console.log("SaveMapping", APIResponse.data);
    if (APIResponse.status === 200) {
      this.setState({ formLoader: false });
      window.location.assign("/admin/previewloantapedetails");
    } else {
      this.setState({ formLoader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  QueryDatatapes = async () => {
    this.setState({ getLoansLoader: true, loading: true });
    var data = {};
    if (
      sessionStorage.getItem("user_name") === "AdminAG" ||
      sessionStorage.getItem("user_name") === "Trustee"
    ) {
      data.DealName = this.state.DealName;
      data.peer = this.state.peer;
    } else {
      data.DealName =
        this.state.servicerDealName || this.state.servicerDashboardName;
      data.peer = this.state.peer;
    }
    console.log("datata", data);
    const APIResponse = await QueryDatatapes(data);
  
    console.log("FilesRecurring", APIResponse.data);
    if (APIResponse.status === 200) {
      const files = APIResponse.data.result;
      let hasEmptyPrimaryKey = false;
      for (let i = 0; i < files.length; i++) {
        if (!files[i].primaryKey || files[i].primaryKey.trim() === "") {
          hasEmptyPrimaryKey = true;
          break;
        }
      }
  
      if (hasEmptyPrimaryKey) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader1: false,
          uploadDisabled: true,
        });
        const message = "Error: One or more files have an empty primary key.";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 5000,
        });
      } else {
        this.setState({
          getLoansLoader: false,
          files: files,
          loading: false,
          formLoader1: false,
          uploadDisabled: false, 
        });
        let temp = {};
        for (let i = 0; i < files.length; i++) {
          temp[`file${i + 1}`] = "";
        }
        this.setState({
          uploadfiles: temp,
        });
      }
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
      const message = "Something Went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  

  // handleFileChange = (e, index) => {
  //   let uploadedfiles = [...this.state.files];
  //   uploadedfiles[index] = {
  //     ...uploadedfiles[index],
  //     file: e.target.files[0],
  //     fileName: e.target.files[0].name,
  //   };

  //   this.setState({ files: uploadedfiles });
  // };

  handleFileChange = (e, index) => {
    let uploadedfiles = [...this.state.files];
    uploadedfiles[index] = {
      ...uploadedfiles[index],
      file: e.target.files[0],
      fileName: e.target.files[0].name,
    };

    this.setState({ files: uploadedfiles });
  };

  componentDidMount() {
    if (sessionStorage.getItem("user_name") === "AdminAG") {
      sessionStorage.removeItem("selectservicerdate");
      sessionStorage.removeItem("servicerdealname");
      sessionStorage.removeItem("servicerdashboardname");
      sessionStorage.setItem(
        "selectdate",
        this.props.location?.state?.detailsView === undefined
          ? this.state.detailsDate
          : this.props.location?.state?.detailsView
      );
      sessionStorage.setItem(
        "dealname",
        this.props.location?.state?.dealname === undefined
          ? this.state.DealName
          : this.props.location?.state?.dealname
      );
      sessionStorage.setItem(
        "Servicer",
        this.props.location?.state?.servicer === undefined
          ? this.state.ServicerName
          : this.props.location?.state?.servicer
      );
      this.QueryDatatapes();
    } else if (sessionStorage.getItem("user_name") === "Trustee") {
      sessionStorage.removeItem("selectservicerdate");
      sessionStorage.removeItem("servicerdealname");
      sessionStorage.removeItem("servicerdashboardname");
      sessionStorage.setItem(
        "selectdate",
        this.props.location?.state?.detailsView === undefined
          ? this.state.detailsDate
          : this.props.location?.state?.detailsView
      );
      sessionStorage.setItem(
        "dealname",
        this.props.location?.state?.dealname === undefined
          ? this.state.DealName
          : this.props.location?.state?.dealname
      );
      sessionStorage.setItem(
        "Servicer",
        this.props.location?.state?.servicer === undefined
          ? this.state.ServicerName
          : this.props.location?.state?.servicer
      );
      this.QueryDatatapes();
    } else {
      sessionStorage.removeItem("selectdate");
      sessionStorage.removeItem("dealname");
      sessionStorage.setItem(
        "selectservicerdate",
        this.props.location?.state?.ServicerPaymentDate === undefined
          ? this.state.servicerPaymentDate
          : this.props.location?.state?.ServicerPaymentDate
      );
      sessionStorage.setItem(
        "servicerdealname",
        this.props.location?.state?.ServicerDealName === undefined
          ? this.state.servicerDealName
          : this.props.location?.state?.ServicerDealName
      );
      sessionStorage.setItem(
        "servicerdashboardname",
        this.props.location?.state?.ServicerDashboardName === undefined
          ? this.state.servicerDashboardName
          : this.props.location?.state?.ServicerDashboardName
      );

      this.QueryDatatapes();
    }
  }

  render() {
    const servicerDate = this.state.servicerPaymentDate;
    console.log("servicerDate", servicerDate, this.state.detailsDate);
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar
            activeComponent={
              sessionStorage.getItem("user_name") === "Servicer"
                ? "ServicerMainDashboard"
                : "Dashboard"
            }
          />
          <div className="content">
            <div className="header">
              <Header></Header>
            </div>
            {this.state.screendisplay === true ? (
              <div className="page-contentofpool1">
                <div className="row1">
                  <div className="col-12 col-sm-6 col-md-2 d-flex justigy-content-center align-center hellocard">
                    <KeyboardBackspaceIcon
                      onClick={() => this.goBackToDashBoard()}
                      className="left-arrow-muis1 left-arrow-servicer"
                    ></KeyboardBackspaceIcon>
                    <h3 className="headerdashboard">Servicer Details</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-md-10 hellocard">
                    <div className="recurring_details">
                      <div>
                        <label className="dealInfo">Deal Name : </label>
                        <h6 className="dealInfo1">
                          {sessionStorage.getItem("user_name") === "AdminAG" ||
                          sessionStorage.getItem("user_name") === "trustee"
                            ? this.state.DealName
                            : this.state.servicerDealName ||
                              this.state.servicerDashboardName}
                        </h6>
                      </div>
                      <div>
                        <label className="dealInfo">Payment Date : </label>
                        <h6 className="dealInfo1">
                          {sessionStorage.getItem("user_name") === "AdminAG" ||
                          sessionStorage.getItem("user_name") === "trustee"
                            ? this.state.detailsDate
                            : this.state.servicerPaymentDate}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row1">
                  <div>
                    <div style={{ marginBottom: "5rem" }}>
                      <button
                        type="button"
                        onClick={() => this.handleClickUploadLoanTapes()}
                        className={
                          this.state.activeInsights1 == true
                            ? "issuerDashboard-table-top-button-insights-active"
                            : "issuerDashboard-table-top-button-insights"
                        }
                      >
                        Upload Loan Tapes
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickPreviewLoanTape()}
                        className={
                          this.state.activeInsights2 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Preview Loan Tape
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickMapFields()}
                        className={
                          this.state.activeInsights3 == true
                            ? "issuerDashboard-table-top-button-workbench-active"
                            : "issuerDashboard-table-top-button-workbench"
                        }
                      >
                        Map Fields
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickPreviewMappedFields()}
                        className={
                          this.state.activeInsights4 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Mapped Tape
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickSummarize()}
                        className={
                          this.state.activeInsights5 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Summarize
                      </button>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="borderclass">
                    <h5 className="headingpayment1-css-uploadloantape">
                      File Upload (*.xls, *.xlsx, *.csv)
                    </h5>

                    <div className="">
                      <form
                        className="form-container-setup"
                        onSubmit={
                          this.state.files.length === 0 ||
                          this.state.files.length === 1
                            ? this.onSubmit1
                            : this.onSubmit2
                        }
                      >
                        <div className="servicer-popup-inner-container">
                          <div className="servicer-popup-inner-container">
                            {this.state.files.map((fileData, index) => (
                              <div
                                className="uploadfieldscontainer-Selectfile"
                                key={index}
                              >
                                <h6 className="servicer-popup-inner-container-heading">
                                  <span
                                    title={fileData.fileName}
                                  >
                                    {fileData.file
                                      ? fileData.fileName.length > 25
                                        ? `${fileData.fileName.substring(
                                            0,
                                            25
                                          )}...`
                                        : fileData.fileName
                                      : fileData.name}
                                  </span>
                                </h6>
                                <div className="kyc-card__button-container_upload">
                                  <div>
                                    <button
                                      className="card__button"
                                      style={{
                                        position: "relative",
                                        backgroundColor:
                                          fileData.file || fileData.fileName
                                            ? "#FFFFFF"
                                            : "",
                                        borderColor:
                                          fileData.file || fileData.fileName
                                            ? "#FFFFFF"
                                            : "#FFFFFF",
                                      }}
                                    >
                                      <label
                                        htmlFor={`icon-button-file-id${index}`}
                                        className="upload-button-label1"
                                      >
                                        {fileData.file
                                          ? "Replace"
                                          : "Select File"}
                                      </label>
                                      <input
                                        required
                                        id={`icon-button-file-id${index}`}
                                        type="file"
                                        accept=".csv,.xlsm, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        style={{
                                          position: "absolute",
                                          width: "60%",
                                          height: "100%",
                                          cursor: "pointer",
                                          top: "0",
                                          right: "0px",
                                          opacity: "0",
                                          border: "1.2px solid #212121",
                                        }}
                                        onChange={(e) =>
                                          
                                          this.handleFileChange(e, index)
                                        }
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="servicer-popup-upload-btn-contianer-2">
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={this.state.uploadDisabled}
                              >
                                Upload
                                {this.state.formLoader === true ? (
                                  <CircularProgress
                                    size="22px"
                                    color="primary"
                                  />
                                ) : (
                                  ""
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    fontSize: "19px",
                    marginLeft: "35rem",
                    marginBottom: "-7rem",
                    marginTop: "6rem",
                  }}
                >
                  OR
                </div>

                <div className="borderclass_apidoc">
                  <h5 className="headingpayment1-css-uploadloantape">
                    API Integration
                  </h5>
                  <div className="">
                    <form
                      className="form-container-setup"
                    >
                      <div className="servicer-popup-inner-container">
                        <div className="uploadfieldscontainer">
                          <h6 className="servicer-popup-inner-container-heading">
                            API Documentation
                          </h6>
                        </div>
                        <div className="servicer-popup-upload-btn-contianer-3">
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Download
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(UploadLoanTape);
